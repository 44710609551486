<template>
  <div id="app">
    <van-nav-bar title="周期巡检" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon v-if="finishWx" name="qr" size="20" @click="searchWorkOrder" />
      </template>
    </van-nav-bar>
    <van-tabs v-model:active="active" :ellipsis="false" @click="clickTabs">
      <van-tab>
        <template #title>全部 ({{ totalPm }})</template>
      </van-tab>
      <van-tab>
        <template #title>待维修 ({{ todoPm }})</template>
      </van-tab>
      <van-tab>
        <template #title>完成 ({{ donePm }})</template>
      </van-tab>
      <van-tab>
        <template #title>超时 ({{ timeoutPm }})</template>
      </van-tab>
    </van-tabs>
    <!--    <van-search hidden v-model="mobileSearch" placeholder="请输入搜索关键词" @search="onSearch" class="asset-search" />-->
    <div class="common-list" style="margin-top: 10px;">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMore" offset="1" :immediate-check="false">
          <van-cell v-for="(item, index) in pmList" :key="index">
            <div class="cell-title" @click="goUrls(item.id)">
              <p class="title-left">
                <span v-html="statusFormat(item)" />
                <span>{{ item.eq.name }}</span>
                <span style="display: block;">执行人：{{item.members}}</span>
                <span v-if="item.eq" style="display: block;">
                  <span>{{ item.eq.coding }}-{{ item.eq.name }}</span><br>
                  <span v-if="item.eq.process">{{ item.eq.process.name }}</span>
                  <span v-if="item.eq.position">{{ item.eq.position }}</span>
                </span>
                <span v-else>该设备已被删除</span>
              </p>
              <p class="title-right">
                <span class="create-time" style="margin-right: 5px; display: inline-block;">安排时间：{{ item.arrange_date }}</span>
              </p>
            </div>
            <div></div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import {Notify, Toast} from 'vant'
import API from '@/api/workorder/pm'
import wx from "weixin-js-sdk";
import SELECT from "@/api/select";
import WxApi from "@/api/wx";
import {mapGetters} from "vuex";
import {planPeriodUnit} from "@/utils/options"
export default {
  inject: ['goUrl', 'getPmStatus', 'getScope', 'isWorkorderMember', 'dateTimeFormat'],
  data() {
    return {
      active: 0,
      query: { page: 0, limit: 15, plan_category: 1 },
      mobileSearch: '',
      pmList: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishLoading: false,
      finishWx: false,
      totalPm: 0,
      todoPm: 0,
      donePm: 0,
      timeoutPm: 0,
      qrPm: 0,
      qrCoding: null,
      eqId: null, //扫码后得到的id，url中携带这个id才可以直接打开度数的选项卡
      scope: null,
      planPeriodUnit
    }
  },
  computed: {
    ...mapGetters(['userId'])
  },
  created() {
    this.scope = this.getScope(this.userId)
    this.getConfig()
    // const query = sessionStorage.getItem('qrEq1')
    // if (query) {
    //   this.query.equipment_id = query
    // } else {
    //   this.index()
    //   this.qrPm = 0
    // }
    // sessionStorage.removeItem('qrEq1')
    localStorage.removeItem('QrTime')
    this.index()
  },
  methods: {
    index(auto) {
      this.finishLoading = false
      this.query.page += 1
      if (this.refreshing) {
        this.pmList = []
        this.refreshing = false
      }
      return API.pmList(this.query).then(res=> {
        this.totalPm = res.data.m_total
        this.todoPm = res.data.todo
        this.donePm = res.data.done
        this.timeoutPm = res.data.timeout

        if (res.data.error_code === 888) {
          Toast('暂无工单')
          this.pmList = []
        } else {
          if (this.totalPm === 1 && auto === 'auto') {
            const toId = res.data.data.data[0].id

            //记录扫码时间

            const url = `/workorder/pm2_detail/${toId}`
            this.$router.push({
              path: url,
              query: {eqId: this.eqId}
            })
          }
          if (res.data.data.data && res.data.data.data.length > 0) {
            res.data.data.data.forEach(item => {
              item.period_unit = ''
              if (item.plan) {
                item.period_unit = this.planPeriodUnit[item.plan.period_unit]
              }
              const om = []
              item.other_member.map(i => {
                if (i.admin) {
                  om.push(i.admin.name)
                } else {
                  om.push('删除')
                }
              })
              item.members = om.join(',')
            })
          }
          this.pmList.push(...res.data.data.data)
          this.loading = false
          if (this.active === 1) {
            // 待维修
            this.pmList.length >= this.todoPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 2) {
            // 完成
            this.pmList.length >= this.donePm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 3) {
            // 超时
            this.pmList.length >= this.timeoutPm ? (this.finished = true) : (this.finished = false)
          } else if (this.active === 4) {
            // 扫码
            // if (!this.qrCoding) {
            //   this.pmList.length = 0
            // }
            // this.pmList.length >= this.qrPm ? (this.finished = true) : (this.finished = false)
          } else {
            // 全部
            this.pmList.length >= this.totalPm ? (this.finished = true) : (this.finished = false)
          }
        }
      }).finally(() => {
        this.finishLoading = true
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onSearch() {
      this.query.mobileSearch = this.mobileSearch
      this.query.page = 0
      this.eqList = []
      this.index()
    },
    onRefresh() {
      this.pmList = []
      this.query.page = 0
      this.finished = true
      this.index()
    },
    loadMore() {
      if (this.finishLoading === true) {
        this.index()
      }
    },
    goUrls(id) {
      const url = `/workorder/pm2_detail/${id}`
      if (!this.eqId) {
        this.goUrl(url)
      } else {
        this.$router.push({
          path: url,
          query: {eqId: this.eqId}
        })
      }
    },
    clickTabs(name) {
      this.pmList = []
      this.query.page = 0
      this.finished = true
      // 全部，待维修 完成 超时
      if (name === 1) {
        console.log('待维修')
        this.query.status = 1
      } else if (name === 2) {
        console.log('完成')
        this.query.status = 2
      } else if (name === 3) {
        console.log('超时')
        this.query.status = 10
      } else if (name === 4) {
        console.log('扫码')
        this.query.status = ''
      }  else {
        this.query.status = ''
      }
      this.index()
    },
    statusFormat(row) {
      return this.getPmStatus(row)
    },

    searchWorkOrder() {
      const self = this
      this.pmIsLoading = true

      this.checkJsApi("scanQRCode").then((rel) => {
        if (rel) {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              const code = res.resultStr
              const coding = code.split('|')
              if (coding.length !== 2) {
                alert('此二维码格式不对，请联系管理员！！')
              }
              if (coding[0] === 'e') {
                // 设备

                self.selectWorkorderByQrCode(coding[1], 'auto')
              } else {
                alert('这不是设备的二维码')
              }
            }
          })
        }
      })
    },
    selectWorkorderByQrCode(coding, auto) {
      SELECT.eqList({coding}).then(res => {
        if (res.data.total !== 1) {
          Notify({ type: 'danger', message: '二维码无效' })
        } else {
          const eqId = res.data.data[0].id
          this.eqId = eqId
          sessionStorage.setItem('qrEq1', eqId)
          const date = this.dateTimeFormat((new Date()).valueOf())
          localStorage.setItem('QrTime', String(date))
          this.query.equipment_id = eqId
          this.query.page = 0
          this.pmList = []
          this.index(auto)
        }
      })
    },
    getConfig() {
      const self = this
      let url = /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.localStorage.getItem('scanUrl');

      WxApi.wxJSTicket({ url: url }).then((res) => {
        const data = res.data;
        wx.config({
          beta: true,
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: data.appId, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature, // 必填，签名
          jsApiList: ['chooseImage','uploadImage','getLocalImgData','scanQRCode','checkJsApi'] // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log("成功")
          self.finishWx = true
        })
        wx.error(function (err) {
          console.log(err)
        })
      })
    },
    checkJsApi(api) {
      //检测api是否可用
      return new Promise((rel, err) => {
        wx.checkJsApi({
          jsApiList: [api], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            if (res.checkResult && res.checkResult.chooseImage == false) {
              this.$toast("微信版本较低，请升级后重试！")
              err("版本低")
            } else {
              rel(true)
            }
          }
        })
      })
    }
  }
}
</script>
<style>
.asset-search{
  background-color: #ebeef5;
}
.status0{
  color: blue;
}
.status0 span{
  background-color: blue !important;
}
.status1{
  color: orange;
}
.status1 span{
  background-color: orange !important;
}
.status2{
  color: green;
}
.status2 span{
  background-color: green !important;
}
</style>
